<template>
  <v-app>
    <core-app-bar />
    <core-drawer />

    <v-content>
      <router-view />
    </v-content>
  </v-app>
</template>

<script>
import { mapState, mapMutations } from "vuex";

export default {
  name: "App",

  components: {
    CoreAppBar: () => import("@/components/core/AppBar"),
    CoreDrawer: () => import("@/components/core/Drawer"),
  },
  data: () => ({}),

  computed: {
    ...mapState(["drawer"]),
  },
  methods: {
    ...mapMutations(["toggleDrawer"]),
  },
};
</script>

<style>
.v-card {
  border-radius: 6px;
}
a {
  text-decoration: none;
}
</style>
