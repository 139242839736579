import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

export default new Vuetify({
  // theme: {
  //   dark: true,
  //   themes: {
  //     dark: {
  //       primary: "#FFF",
  //       secondary: "#1697F6",
  //       accent: "#8D99AE",
  //     },
  //   },
  // },
});
