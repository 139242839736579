import Vue from "vue";
import Vuex from "vuex";
import home from "@/store/modules/home";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    drawer: false,
  },
  getters: {},
  mutations: {
    toggleDrawer(state) {
      state.drawer = !state.drawer;
    },

    setDrawer(state, value) {
      state.drawer = value;
    },
  },
  actions: {},
  modules: {
    home,
  },
});
