export default {
  namespaced: true,

  state: {
    articles: [
      {
        id: "josun",
        img: "home/josun_test_2.jpg",
        altImg: "home/josun_screenshot.jpg",
        needAltImg: true,
        title: "1. 조선셀피",
        author: "Mango",
        subTitle: "연인 또는 친구의 전생 모습을 확인할 수 있음",
        content: [
          {
            type: "p",
            content: "전생의 모습이 궁금한가요?",
          },
          {
            type: "p",
            content:
              "당신을 위해 AI를 개발했습니다. 사진 한 장만으로도 조선시대 선비, 주모, 한량, 내시, 왕 등 다양한 나의 모습을 확인 해보세요",
          },
          {
            type: "link",
            link: "https://mango-data-station.du.r.appspot.com/josun/home",
            content: "다운로드",
          },
        ],
      },
      // {
      //   img: 'articles/article2a.png',
      //   altImg: 'articles/article2b.png',
      //   title: '10 Indie Games to Watch in 2019',
      //   slug: 'alienware-area-52',
      //   author: 'John Smith',
      //   ago: '12h ago',
      // },
    ],
  },
  getters: {
    parsedArticles: (state) => {
      return state.articles.map((item) => ({
        ...item,
      }));
    },
  },
};
